import React from 'react'

const WhyChoose = () => {


  return (
    <section className="choosedesire ychoose otc">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Why Choose </span>us for</span>
              DeFi Crowdfunding Platform Development?</h3>
            <p className="pharagraph head">Our DeFi Crowdfunding Platform development service is the finest and top-notch service that you find in the crypto industry. We offer a single package in which you can find all that you need for developing an outstanding DeFi Crowdfunding Platform platform.
            </p>
          </div>
        </div>
        <div className="row table-content firstblck">
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/deficrowdfunding/Unlimited potential.svg" alt="Unlimited potential" />
                </div>
                <h3>
                  Unlimited potential
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                  Our DeFi Crowdfunding platform development services can be expanded vertically and horizontally.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/deficrowdfunding/Complete ownership.svg" alt="Complete ownership" />
                </div>
                <h3>
                  Complete ownership
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                  We will build a custom crowdfunding platform for you that you will own 100%.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row table-content firstblck">
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/deficrowdfunding/Hybrid solution.svg" alt="Hybrid solution" />
                </div>
                <h3>
                  Hybrid solution
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                  Our DeFi Crowdfunding platform will be able to accommodate multiple blockchain networks on multiple devices.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/deficrowdfunding/Cross train connectivity.svg" alt="Cross train connectivity" />
                </div>
                <h3>
                  Cross train connectivity
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                  Peer-to-peer crowdfunding exchanges will be facilitated through multiple blockchains.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row table-content lastblck">
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/deficrowdfunding/Ongoing support.svg" alt="Ongoing support" />
                </div>
                <h3>
                  Ongoing support
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                  We are always ready to provide ongoing support for maintenance as well as your expansion plans.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/deficrowdfunding/Years of experience.svg" alt="Years of experience" />
                </div>
                <h3>
                  Years of experience
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                  Our blockchain development team has years of experience deploying cutting-edge DeFi platforms for our worldwide clients.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default WhyChoose