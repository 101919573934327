import React from 'react'




class UsecaseOf extends React.Component {


  render() {

    return (
      <section className="usecase">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Use Case</span> of our</span>
                DeFi Crowdfunding Platform Development</h3>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/deficrowdfunding/usecase.svg" alt="Use Case of DeFi Crowdfunding Platform Development" />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph" ><b>Investments in assets -</b> DeFi Crowdfunding platforms can be used to raise funds to make investments in high-value assets.
              </p>
              <p className="pharagraph" ><b>Analytical technologies -</b> Many users are interested in investing in analytical technologies for online marketing, digital marketing and other such business promotional needs.
              </p>
              <p className="pharagraph" ><b>Tokenized investments -</b>  People want to convert their real-world assets into tokens and then use these tokens to invest through DeFi Crowdfunding platforms.
              </p>
              <p className="pharagraph" ><b>Payment solutions -</b>   DeFi Crowdfunding platforms can be used as payment alternatives.
              </p>
              <p className="pharagraph" ><b>Sweat equity -</b> Through the DeFi Crowdfunding platforms sweat equity can be raised as a guarantee or insurance.
              </p>
              <p className="pharagraph" ><b>Infrastructure development -</b> Many infrastructure development companies are using DeFi Crowdfunding platforms to raise funds for mega projects.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default UsecaseOf