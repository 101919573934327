import React from 'react'




class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3">What is</span> DeFi Crowdfunding Platform Development?</h2>
            </div> 
          </div>
          <div className="row table-content orderflex">
            <div className="col-lg-8 col-md-12 left-side order2">
              <p className="pharagraph" >It is one of the easiest ways for startups to raise funds for their crypto business. Innovative entrepreneurs can use this DeFi platform to crowdfund for their upcoming projects. With a decentralized working principle, this platform automates the fundraising process quicker than the traditional fundraising method.  
              </p>
              <p className="pharagraph" >Since it is peer-to-peer crowdfunding, the process is direct and transparent. Hence, it doesn’t require all the stakeholders to go through third-party institutions. Funds can be directly exchanged between investors and fundraisers through the DeFi Crowdfunding platform.
              </p>
            </div>
            <div className="col-lg-4 col-md-12 right-side order1" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/deficrowdfunding/what-is-defi-crowdfunding-platform-development.svg" alt="What is DeFi Crowdfunding Development" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Whatis