import * as React from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BannerSection from "../components/deficrowdfunding/Banner"
import Whatis from "../components/deficrowdfunding/Whatis"
import WhyShould from "../components/deficrowdfunding/WhyShould"
import TopFeatures from "../components/deficrowdfunding/TopFeatures"
import HowOur from "../components/deficrowdfunding/HowOur"
import CoreFeatures from "../components/deficrowdfunding/CoreFeatures"
import SecurityofOur from "../components/deficrowdfunding/SecurityofOur"
import UsecaseOf from "../components/deficrowdfunding/UsecaseOf"
import WhyChoose from "../components/deficrowdfunding/WhyChoose"
import Testimonial from "../components/whitelabelpage/Testimonial"
import Review from "../components/Landing/Review"
import PortfolioSection from "../components/Landing/Portfolio"
import DevApproach from "../components/deficrowdfunding/DevApproach"
import TechStack from "../components/deficrowdfunding/TechStack"
import FaqSection from "../components/deficrowdfunding/FaqSection"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"

// import Header from "../components/header"




const DeFiCrowdfunding = () => (
  <section className="dev-page">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>DeFi Crowdfunding Platform Development Services | Coinsclone</title>
        <meta name="description" content="Get premium DeFi crowdfunding platform development services from Coinsclone and raise the required funds for your upcoming crypto-based projects quickly." />
        <meta name="keywords" content="DeFi Crowdfunding Platform Development Company, DeFi Crowdfunding Platform Development Services, Launch your own DeFi Crowdfunding Platform" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="DeFi Crowdfunding Platform Development Services | Coinsclone" />
        <meta property="og:description" content="Get premium DeFi crowdfunding platform development services from Coinsclone and raise the required funds for your upcoming crypto-based projects quickly." />
        <meta property="og:url" content="https://www.coinsclone.com/defi-crowdfunding-platform-development/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/defi-crowdfunding-platform-development.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Get premium DeFi crowdfunding platform development services from Coinsclone and raise the required funds for your upcoming crypto-based projects quickly." />
        <meta name="twitter:title" content="DeFi Crowdfunding Platform Development Services | Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/defi-crowdfunding-platform-development.png" />
        <link rel="canonical" href="https://www.coinsclone.com/defi-crowdfunding-platform-development/" />
      </Helmet>
      {/* <Header /> */}
      <BannerSection />
      <div className="breadcrumb">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | DeFi Crowdfunding Platform Development</span>
        </div>
      </div>
      <Whatis />
      <WhyShould />
      <TopFeatures />
      <HowOur />
      <CoreFeatures />
      <SecurityofOur />
      <UsecaseOf />
      <WhyChoose />
      <Testimonial />
      <Review />
      <PortfolioSection />
      <DevApproach />
      <TechStack />
      <FaqSection />
      <UserFriendly />
      
    </Layout>
  </section>
)

export default DeFiCrowdfunding
