import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Top Features</span> Of our DeFi Crowdfunding Platform Development</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Reward Based Crowdfunding</h4>
                <p className="pharagraph">This crowdfunding model works by rewarding investors for contributing to the growth of the business. The company may issue rewards in the form of services or utilities for the contributions of people.
                </p>
                <div className="text-left ">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/deficrowdfunding/Reward-Based-Crowdfunding.png" alt="Reward-Based Crowdfunding" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/deficrowdfunding/Donation-Based-Crowdfunding.png" alt="Donation-Based Crowdfunding" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Donation Based Crowdfunding</h4>
                <p className="pharagraph">Donation-based crowdfunding is the most preferred mode of fundraising. A company initiates the campaign and spreads awareness about their business idea using social media and other forms of marketing.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Peer to Peer Crowdfunding</h4>
                <p className="pharagraph">Peer-to-peer crowdfunding model is an efficient fundraising model that matches the pair of investors online with great investment opportunities. As a result, lenders can earn higher returns compared to savings and investments.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/deficrowdfunding/Peer-to-Peer-to-Crowdfunding.png" alt="Peer-to-Peer to Crowdfunding" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/deficrowdfunding/Hybrid-crowdfunding.png" alt="Hybrid crowdfunding" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Hybrid crowdfunding</h4>
                <p className="pharagraph">Hybrid crowdfunding is the most effective mode of fundraising. Our company supports multiple blockchains and spreads awareness about their funding idea using social media and other forms of marketing.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Equity-Based Crowdfunding</h4>
                <p className="pharagraph">In this type of crowdfunding method, the investors get the shares of the company upon investing an amount in the program. The more the investment amount the higher the proportion of the share awarded to the investors. The shares are equivalent to the stakes bought.</p>
                <div className="text-left">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/deficrowdfunding/Equity-Based-Crowdfunding.png" alt="Equity-Based Crowdfunding" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures