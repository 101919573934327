import React ,{useEffect, useState} from 'react'
import { StaticImage } from 'gatsby-plugin-image'




const HowOur = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <section className="how-our">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h2 className="heading-h2"><span className="heading-h3">How Does our
          </span>
          DeFi Crowdfunding Platform Development Work?
          </h2>
        </div>
        { isDesktop && (
          <svg xmlns="http://www.w3.org/2000/svg" width="1058.389" height="1112.27" viewBox="0 0 1058.389 1112.27">
          <g id="Flow" transform="translate(-427.417 -5099.559)">
            <g id="Ellipse_1376" data-name="Ellipse 1376" transform="translate(461 5128.096)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
              <circle cx={108} cy={108} r={108} stroke="none" />
              <circle cx={108} cy={108} r={107} fill="none" />
            </g>
            <g id="Ellipse_196" data-name="Ellipse 196" transform="translate(1124 5132.096)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
              <circle cx={91} cy={91} r={91} stroke="none" />
              <circle cx={91} cy={91} r={90} fill="none" />
            </g>
            <g id="User_Login" data-name="User Login" transform="translate(1007.824 5141.156)">
              <g id="Group_27493" data-name="Group 27493" transform="translate(138.521 2.228)">
                <g id="Group_27491" data-name="Group 27491" transform="translate(38.079 3.509)">
                  <path id="Path_20476" data-name="Path 20476" d="M1087.494,1075c1.084,0,2.149-.054,3.193-.106.864-.043,1.757-.089,2.62-.1a.558.558,0,0,0,0-1.1c-.89.013-1.795.057-2.668.1-6.537.33-13.3.674-19.888-11.994a58.7,58.7,0,0,1-5.334-28.226,47.454,47.454,0,0,1,16.807-34.7.559.559,0,1,0-.706-.843,48.524,48.524,0,0,0-17.2,35.483,59.879,59.879,0,0,0,5.455,28.792C1075.578,1073.493,1081.76,1075,1087.494,1075Z" transform="translate(-1064.21 -997.834)" fill="#303c60" />
                </g>
                <g id="Group_27492" data-name="Group 27492" transform="translate(39.955 10.522)">
                  <path id="Path_20477" data-name="Path 20477" d="M1100.907,1096.521c.255,0,.51,0,.767-.019a.559.559,0,1,0-.054-1.1c-.236.011-.475.017-.709.017-6.824,0-13.124-4.832-17.382-13.37-7-14.039-7.677-37.068,4.841-52.1a.559.559,0,1,0-.845-.7c-12.824,15.39-12.141,38.945-4.988,53.294C1087,1091.476,1093.664,1096.521,1100.907,1096.521Z" transform="translate(-1077.45 -1028.967)" fill="#303c60" />
                </g>
                <path id="Path_20478" data-name="Path 20478" d="M937.23,989.962c-19.736-15.325-38.287-7.2-44.931,13.541-6.206,19.375,1.746,61.006,31.2,53.752,30.471-7.505,33.009-16.833,32.769-30.391a48.114,48.114,0,0,0-19.039-36.9Z" transform="translate(-849.341 -982.021)" fill="#303c60" />
                <path id="Path_20479" data-name="Path 20479" d="M848.438,1289.45l28.222,10.531s-7.9,57.325-8.576,79.217,10.382,40.883,10.382,40.883h-73.8s-10.156-59.615-8.125-80.6,16.023-42.735,16.7-43.259,17.6-4.361,17.6-4.361Z" transform="translate(-750.285 -1220.068)" fill="#f5f5f5" />
                <path id="Path_20480" data-name="Path 20480" d="M1133.783,1602.11s-2.841,26.625-4.016,27.986-44.5,22.566-44.5,22.566l62.966,4.292Z" transform="translate(-1085.268 -1462.169)" fill="#408beb" />
                <path id="Path_20481" data-name="Path 20481" d="M1095.965,1319.44c-7,.153-8.9,11.592-10.542,20.092S1095.965,1319.44,1095.965,1319.44Z" transform="translate(-1033.002 -1243.281)" fill="#408beb" />
                <path id="Path_20482" data-name="Path 20482" d="M805.986,1341.349c3.837-13.767,24.452-52.1,24.452-52.1l27,10.577s-6.191,44.927-8.081,70.423L839.1,1419.922H805.977S802.149,1355.127,805.986,1341.349Z" transform="translate(-731.07 -1219.91)" fill="#4b9aff" />
                <path id="Path_20483" data-name="Path 20483" d="M893.84,1737.1l-30.44-6.988,2.484,25.954Z" transform="translate(-780.815 -1561.274)" fill="#408beb" />
                <path id="Path_20484" data-name="Path 20484" d="M825.943,1444.672c4.966-6.723,9.821-14.118,5.092-29.565s-5.191-20.989-3.538-34.982,9.175-36.336,23.845-37.012c0,0,.484,4.893,1.266,12.7-2.381,18.451-6.219,49.969-6.682,64.933-.678,21.892,10.382,40.883,10.382,40.883l-60.032,1.32S820.978,1451.394,825.943,1444.672Z" transform="translate(-728.134 -1261.62)" fill="#408beb" />
                <path id="Path_20485" data-name="Path 20485" d="M1032.18,1352.13c2.031-20.989,16.023-42.736,16.7-43.259.419-.324,7.042-1.912,12.042-3.08-1.471,3.469-16.974,40.419-16.33,55.64.678,15.977-.019,71.31-.019,71.31h-4.269S1030.151,1373.113,1032.18,1352.13Z" transform="translate(-985.92 -1232.718)" fill="#4b9aff" />
              </g>
              <rect id="Rectangle_8831" data-name="Rectangle 8831" width="361.169" height="7.127" rx="1.914" transform="translate(0 198.679)" fill="#2e5b9a" />
              <g id="Group_27494" data-name="Group 27494" transform="translate(56.132 130.935)">
                <path id="Path_20486" data-name="Path 20486" d="M1018.688,1825.693h130.368a8.367,8.367,0,0,0-8.367-8.367H1018.68Z" transform="translate(-988.684 -1757.513)" fill="#a8ceff" />
                <path id="Path_20487" data-name="Path 20487" d="M1318.327,1620.5h69.634l-29.566-65.053a5.316,5.316,0,0,0-4.841-3.117h-62.987a2.075,2.075,0,0,0-1.889,2.936Z" transform="translate(-1288.484 -1552.315)" fill="#a8ceff" />
                <path id="Path_20488" data-name="Path 20488" d="M1314.9,1552.32h57.494a5.318,5.318,0,0,1,4.841,3.117l29.572,65.057h-64.147L1313,1555.254a2.076,2.076,0,0,1,1.894-2.934Z" transform="translate(-1312.817 -1552.32)" fill="#75a1de" />
                <path id="Path_20489" data-name="Path 20489" d="M1528.5,1673.266c2.437,5.051,6.392,8.192,8.835,7.014s2.445-6.228,0-11.279-6.392-8.192-8.835-7.013S1526.063,1668.2,1528.5,1673.266Z" transform="translate(-1493.443 -1637.038)" fill="#6695d6" />
              </g>
              <g id="Group_27495" data-name="Group 27495" transform="translate(176.424)">
                <path id="Path_20490" data-name="Path 20490" d="M938.853,1107.6s5.917,30.2,12.456,35.5-15.049,30.816-22.529,24.277-.519-20.853-.519-20.853,4.048-2.491-2.8-18.371,11.59-27.712,11.59-27.712Z" transform="translate(-891.062 -1071.486)" fill="#fbbd9e" />
                <path id="Path_20491" data-name="Path 20491" d="M987.9,1100.44l1.8,7.161s.823,4.2,2.2,9.745a54,54,0,0,1-11.609,24.614,41.667,41.667,0,0,0-3.975-13.81C969.465,1112.263,987.9,1100.44,987.9,1100.44Z" transform="translate(-941.912 -1071.48)" fill="#f2a883" />
                <path id="Path_20492" data-name="Path 20492" d="M993.531,1073.376c3.975,10.965,13.692,19.766,19.86,17.734,10.078-3.318,17.414-11.2,15.682-28.609-2.048-20.509-17.822-22.86-27.09-20.24S989.137,1061.237,993.531,1073.376Z" transform="translate(-983.149 -1025.76)" fill="#fbbd9e" />
                <path id="Path_20493" data-name="Path 20493" d="M907.568,1289.25l9.533,3.733-15.027,19.256,4.282,7.675a60.671,60.671,0,0,0-20.177,34.13,201.36,201.36,0,0,0-4.191,41.693s-1.154-45.656,1.117-54.381C887.536,1324.421,907.568,1289.25,907.568,1289.25Z" transform="translate(-846.103 -1217.677)" fill="#408beb" />
                <path id="Path_20494" data-name="Path 20494" d="M1052.144,1305.79s-11.9,30.535-13.778,39.135-2.27,50.593-2.27,50.593-5.47-36.617-3.889-47.45,3.837-20.622,3.837-20.622l7.222-5.333s1.365-14.523,2.134-14.709S1052.144,1305.79,1052.144,1305.79Z" transform="translate(-1015.059 -1230.492)" fill="#408beb" />
                <path id="Path_20495" data-name="Path 20495" d="M1003.469,1773.761c-23.137-7.012-20.333-10.518-28.747-6.544s-18.958,6.671-14.024,7.946,12.387-2.8,12.387-2.8-1.169,6.31,3.039,8.179,22.886,2.549,33.3-.361S1003.469,1773.761,1003.469,1773.761Z" transform="translate(-959.436 -1586.485)" fill="#fbbd9e" />
                <path id="Path_20496" data-name="Path 20496" d="M975.721,1797.11h-4.606l2.3,13.082s9.073-1.346,8.4-2.249S975.721,1797.11,975.721,1797.11Z" transform="translate(-931.036 -1610.93)" fill="#a2dff2" />
                <path id="Path_20497" data-name="Path 20497" d="M734.744,1336.111c9.028,15.8,11.892,24.415,19.25,53.979s10.315,43.009,2.191,46.9c-6.723,3.216-55.67,17.9-61.613,16.088-2.709.155-6.865-13.832-6.865-13.832l44.554-19.861s-12.9-30.784-15.122-51.006,7.673-32.274,12.638-32.274Z" transform="translate(-646.275 -1253.974)" fill="#4b9aff" />
                <path id="Path_20498" data-name="Path 20498" d="M962.29,1118.529c2.18,6.844,1.246,8.239,4.262,8.879s5.772-2.355,6.666-6.561-.83-8.135-3.849-8.775S960.985,1114.433,962.29,1118.529Z" transform="translate(-920.603 -1080.422)" fill="#fbbd9e" />
                <path id="Path_20499" data-name="Path 20499" d="M940.741,1025.713c11.81,20.989,35.267,17.905,49.526,10.685,11.622-5.887-33.158-28.191-33.158-28.191Z" transform="translate(-934.522 -1000.065)" fill="#303c60" />
                <path id="Path_20500" data-name="Path 20500" d="M851.637,980.288c4.377-13.2,22.313-10.08,30.44,7.485s-4.308,29.311-11.845,25.009S851.637,980.288,851.637,980.288Z" transform="translate(-806.952 -972.151)" fill="#303c60" />
              </g>
            </g>
            <g id="Line">
              <line id="Line_204" data-name="Line 204" y2={174} transform="translate(540.793 5283.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_224" data-name="Line 224" y2={93} transform="translate(599.793 5522.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_226" data-name="Line 226" y2={424} transform="translate(666.793 5720.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_227" data-name="Line 227" x1="390.124" transform="translate(875.793 6181.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_228" data-name="Line 228" y1={159} transform="translate(1265.917 6022.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_229" data-name="Line 229" x1="341.624" transform="translate(771.293 5843.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_230" data-name="Line 230" x1={360} transform="translate(744.917 5666.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_220" data-name="Line 220" y1={654} transform="translate(1265.5 5339.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
            </g>
            <g id="Dots">
              <circle id="Ellipse_134" data-name="Ellipse 134" cx="7.5" cy="7.5" r="7.5" transform="translate(534 5280)" fill="#4b9aff" />
              <circle id="Ellipse_1381" data-name="Ellipse 1381" cx="7.5" cy="7.5" r="7.5" transform="translate(592 5530)" fill="#4b9aff" />
              <circle id="Ellipse_1382" data-name="Ellipse 1382" cx="7.5" cy="7.5" r="7.5" transform="translate(660 5710)" fill="#4b9aff" />
              <circle id="Ellipse_1387" data-name="Ellipse 1387" cx="7.5" cy="7.5" r="7.5" transform="translate(660 5879)" fill="#4b9aff" />
              <circle id="Ellipse_1388" data-name="Ellipse 1388" cx="7.5" cy="7.5" r="7.5" transform="translate(660 6060)" fill="#4b9aff" />
              <circle id="Ellipse_1383" data-name="Ellipse 1383" cx="7.5" cy="7.5" r="7.5" transform="translate(659 5840)" fill="#4b9aff" />
              <circle id="Ellipse_1373" data-name="Ellipse 1373" cx="7.5" cy="7.5" r="7.5" transform="translate(1258 5354.095)" fill="#ff6464" />
              <circle id="Ellipse_1384" data-name="Ellipse 1384" cx="7.5" cy="7.5" r="7.5" transform="translate(1258 5536.095)" fill="#ff6464" />
              <circle id="Ellipse_1386" data-name="Ellipse 1386" cx="7.5" cy="7.5" r="7.5" transform="translate(1258 5696.095)" fill="#ff6464" />
              <circle id="Ellipse_1385" data-name="Ellipse 1385" cx="7.5" cy="7.5" r="7.5" transform="translate(1258 5878.095)" fill="#ff6464" />
            </g>
            <g id="Text">
              <g id="Admin" transform="translate(-318.776 -6507.716)">
                <rect id="Rectangle_298" data-name="Rectangle 298" width={139} height={43} rx="21.5" transform="translate(1434.776 11853.812)" />
                <text id="Admin-2" data-name="Admin" transform="translate(1504.776 11879.812)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-22.561" y={0}>Admin</tspan></text>
              </g>
              <g id="User" transform="translate(-704.685 -6511.235)">
                <rect id="Rectangle_298-2" data-name="Rectangle 298" width={141} height={43} rx="21.5" transform="translate(1253 11827)" />
                <text id="User-2" data-name="User" transform="translate(1324 11853)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-15.414" y={0}>User</tspan></text>
              </g>
              <g id="_2fa" data-name="2fa" transform="translate(26 -93)">
                <g id="Rectangle_45" data-name="Rectangle 45" transform="translate(401.417 5551.829)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="352.389" height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width="350.389" height={60} rx={24} fill="none" />
                </g>
                <text id="Connect_Digital_Wallet" data-name="Connect Digital Wallet" transform="translate(577.417 5587.829)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-87.088" y={0}>Connect Digital Wallet</tspan></text>
              </g>
              <g id="_2fa-2" data-name="2fa">
                <g id="Rectangle_8830" data-name="Rectangle 8830" transform="translate(1039.417 5455.829)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="446.389" height={74} rx={25} stroke="none" />
                  <rect x={1} y={1} width="444.389" height={72} rx={24} fill="none" />
                </g>
                <text id="Manage_User_Wallet_and_Wallet_Address" data-name="Manage User Wallet and Wallet Address" transform="translate(1262.417 5497.829)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700} letterSpacing="0.03em"><tspan x="-163.904" y={0}>Manage User Wallet and Wallet Address</tspan></text>
              </g>
              <g id="KYC" transform="translate(-96 4717.829)">
                <g id="Rectangle_45-2" data-name="Rectangle 45" transform="translate(558.417 897)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={281} height={83} rx={25} stroke="none" />
                  <rect x={1} y={1} width={279} height={81} rx={24} fill="none" />
                </g>
                <text id="Trade_Exchange_Receive_your_Tokens" data-name="Trade/Exchange Receive 
  your Tokens" transform="translate(699.417 932)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-85.022" y={0}>Trade/Exchange Receive </tspan><tspan x="-40.691" y={22}>your Tokens</tspan></text>
              </g>
              <g id="User_Dashboard" data-name="User Dashboard" transform="translate(32.194 5007.194)">
                <g id="Rectangle_45-3" data-name="Rectangle 45" transform="translate(501.223 986.635)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={285} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={283} height={60} rx={24} fill="none" />
                </g>
                <text id="Deposit_Tokens_to_the_Pool" data-name="Deposit Tokens to the Pool" transform="translate(643.223 1022.635)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-90.258" y={0}>Deposit Tokens to the Pool</tspan></text>
              </g>
              <g id="User_Dashboard-2" data-name="User Dashboard" transform="translate(32.194 5163.194)">
                <g id="Rectangle_45-4" data-name="Rectangle 45" transform="translate(447.223 986.635)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={393} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={391} height={60} rx={24} fill="none" />
                </g>
                <text id="Receive_your_earning_with_Trade_fee" data-name="Receive your earning with Trade fee" transform="translate(643.223 1022.635)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-121.667" y={0}>Receive your earning with Trade fee</tspan></text>
              </g>
              <g id="My_Profile" data-name="My Profile" transform="translate(32.194 4925.194)">
                <g id="Rectangle_45-5" data-name="Rectangle 45" transform="translate(531.223 884.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={217} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={215} height={60} rx={24} fill="none" />
                </g>
                <text id="Add_Liquidity" data-name="Add Liquidity" transform="translate(639.223 920.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-46.039" y={0}>Add Liquidity</tspan></text>
              </g>
              <g id="Website_Content" data-name="Website Content" transform="translate(611 4880.095)">
                <g id="Rectangle_45-6" data-name="Rectangle 45" transform="translate(503.417 929.734)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={301} height={62} rx={20} stroke="none" />
                  <rect x={1} y={1} width={299} height={60} rx={19} fill="none" />
                </g>
                <text id="Add_Remove_Liquidity_Pairs" data-name="Add/Remove Liquidity Pairs" transform="translate(654.417 965.734)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-94.717" y={0}>Add/Remove Liquidity Pairs</tspan></text>
              </g>
              <g id="Website_Content-2" data-name="Website Content" transform="translate(611 4767.095)">
                <g id="Rectangle_45-7" data-name="Rectangle 45" transform="translate(503.417 863.734)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={301} height={62} rx={20} stroke="none" />
                  <rect x={1} y={1} width={299} height={60} rx={19} fill="none" />
                </g>
                <text id="Add_Delete_Swap_Pairs" data-name="Add/Delete Swap Pairs" transform="translate(654.417 899.734)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-78.4" y={0}>Add/Delete Swap Pairs</tspan></text>
              </g>
              <g id="Website_Content-3" data-name="Website Content" transform="translate(611 5042.095)">
                <g id="Rectangle_45-8" data-name="Rectangle 45" transform="translate(503.417 956.734)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={301} height={62} rx={20} stroke="none" />
                  <rect x={1} y={1} width={299} height={60} rx={19} fill="none" />
                </g>
                <text id="Admin_Revenue_Settings" data-name="Admin Revenue Settings" transform="translate(654.417 992.734)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-83.356" y={0}>Admin Revenue Settings</tspan></text>
              </g>
            </g>
            <g id="User-3" data-name="User" transform="translate(612.921 5099.559)">
              <path id="Path_43" data-name="Path 43" d="M769.971,527.116c19.925.843,31.192.23,45.717-12.378,9.889-.263,17.75-7.415,17.523-15.942s-8.457-15.251-18.346-14.988l-35.907,1.7c-9.889.263-28.28-.345-28.053,8.182.111,4.187.113,9.616.5,15.3a19.436,19.436,0,0,0,18.564,18.12Z" transform="translate(-739.425 -305.497)" fill="#2f2e41" />
              <path id="Path_44" data-name="Path 44" d="M488.233,487.557l10.288,1.681L509.9,450.357l-15.184-2.481Z" transform="translate(-393.69 -186.114)" fill="#ffb6b6" />
              <path id="Path_45" data-name="Path 45" d="M759.809,529.065c-19.181,4.716-33.344-.6-45.01.094-6.631-11.189-27.8-25.4-16.249-31.385,6.432-3.332,71.816-11.012,80.4-2.148,22.071,22.793-5.332,94.776-5.332,94.776l-15.242-.2-4.87-6.951,3.922-7.575-3.71-6.193,4.246-6.38Z" transform="translate(-661.18 -308.697)" fill="#2f2e41" />
              <path id="Path_46" data-name="Path 46" d="M703.309,692l6.74,1.8,2.907-6.041,1.377,7.188,17.876,4.786a4.16,4.16,0,0,0,4.245-6.714l-11.635-13.681,1.723-6.433-15.255-3.124Z" transform="translate(-615.839 -382.784)" fill="#2f2e41" />
              <path id="Path_47" data-name="Path 47" d="M602.874,473.5l7.508,7.231,31.465-25.518L630.766,444.54Z" transform="translate(-592.637 -184.728)" fill="#ffb6b6" />
              <path id="Path_48" data-name="Path 48" d="M778.539,507.073c-17.583,6.772-32.961-.759-48.153-9.49.906-12.975,1.412-31.684,14.314-30.04,7.185.916,66.573,10.729,68.6,22.9,5.2,31.3-69.338,90.961-69.338,90.961l-12.427-8.827-.055-8.486,7.533-4,.468-7.2,7.12-2.835Z" transform="translate(-707.777 -298.699)" fill="#2f2e41" />
              <path id="Path_49" data-name="Path 49" d="M860.111,667.1l4.519,5.316,5.826-3.318-2.953,6.7,11.985,14.1a4.16,4.16,0,0,0,7.309-3.11L885,668.918l5.074-4.313L879.3,653.362Z" transform="translate(-860.111 -375.959)" fill="#2f2e41" />
              <path id="Path_50" data-name="Path 50" d="M822.8,402.836c1.239,0,1.942-.243,2.214-.764.4-.764,2.952-1.763,2.149-2.92-.56-.806-1.912-1.886-1.392-2.507,2.005-2.394,7.011-71.069,8.19-80.438a18.668,18.668,0,0,0-1.147-8.548c-3.3-9.363-7.815-12.24-16.8-17.972l-2.279-1.458c-1.022-.656-.965.877-.9-1.428.067-2.562.136-5.211-1.119-6.867l-.082-.108-.132-.031c-3.035-.7-13.245-2-19.291.009l-.083.028-.062.062c-1.436,1.436-1.567,4.218-1.694,6.908-.1,2.209-.2.584-1.014,1.49-3.106,3.462-7.786,5.463-11.689,6.6a11.438,11.438,0,0,0-8.274,11.361c.423,12.817.091,33.084,2.278,47.366,3.641,23.77-29.6,58.1-10.56,58.8a.487.487,0,0,0-.015.5C762.452,415.465,818.132,402.836,822.8,402.836ZM761.734,412.83Z" transform="translate(-742.768 -220.277)" fill="#e6e6e6" />
              <ellipse id="Ellipse_6" data-name="Ellipse 6" cx="22.695" cy="22.695" rx="22.695" ry="22.695" transform="translate(38.069 12.221)" fill="#2f2e41" />
              <ellipse id="Ellipse_7" data-name="Ellipse 7" cx="16.316" cy="16.316" rx="16.316" ry="16.316" transform="matrix(-1, -0.028, 0.028, -1, 75.251, 59.961)" fill="#ffb6b6" />
              <path id="Path_51" data-name="Path 51" d="M788.724,210.036l6.612.187a15.737,15.737,0,0,1,15.273,16.158v.146l-.12.076a20.866,20.866,0,0,1-12.142,3.455l-.372-.011-3.653-.166a10.093,10.093,0,0,1-.6-4.977,33.578,33.578,0,0,0-1.288,4.889l-16.391-.746a3.661,3.661,0,0,1-3.479-3.745,15.737,15.737,0,0,1,16.163-15.267Z" transform="translate(-729.483 -191.728)" fill="#2f2e41" />
              <path id="Path_52" data-name="Path 52" d="M695.282,192.932c1.492,1.84,3.888,2.607,6.041,3.6a25.834,25.834,0,0,1,14.468,19.291c.507,3.379.33,6.857,1.189,10.162s3.1,6.586,6.451,7.251c2.264.453,4.57-.361,6.734-1.167q1.786-.66,3.571-1.325-1.711-4.673-3.416-9.346a46.853,46.853,0,0,0,6.594,8.173q6.4-2.372,12.81-4.743c-10.4-.255-8.337-10.3-10.376-20.488-1.121-5.6-11.9-9.676-13.884-15.03A16.2,16.2,0,0,0,696.132,187C693.973,187.795,693.817,191.115,695.282,192.932Z" transform="translate(-622.866 -178.717)" fill="#2f2e41" />
              <ellipse id="Ellipse_8" data-name="Ellipse 8" cx="8.451" cy="8.451" rx="8.451" ry="8.451" transform="translate(62.926 4.063)" fill="#2f2e41" />
              <path id="Path_53" data-name="Path 53" d="M770.279,386.24h39.015a5.045,5.045,0,0,0,5.039-5.039V339.264a5.045,5.045,0,0,0-5.039-5.039H770.279a5.045,5.045,0,0,0-5.039,5.039V381.2a5.045,5.045,0,0,0,5.039,5.039Z" transform="translate(-728.922 -243.339)" fill="#3f3d56" />
              <ellipse id="Ellipse_9" data-name="Ellipse 9" cx="2.922" cy="2.922" rx="2.922" ry="2.922" transform="translate(76.353 94.101)" fill="#e6e6e6" />
              <ellipse id="Ellipse_10" data-name="Ellipse 10" cx="1.753" cy="1.753" rx="1.753" ry="1.753" transform="translate(77.522 95.27)" fill="#3f3d56" />
              <path id="Path_54" data-name="Path 54" d="M755.211,414.909l26.8-6.8,4.163,13.791s-28.831,2.135-29.908,2.145a6.9,6.9,0,1,1-1.055-9.138Z" transform="translate(-688.189 -274.044)" fill="#ffb6b6" />
              <path id="Path_55" data-name="Path 55" d="M698.869,312.754c19.108,10.21,27.481,32.972,39.2,52.976a15.805,15.805,0,0,1-15.43,17.543l-22.933.528,2.875-14.151,7.671-4.358-9.067-21.934Z" transform="translate(-613.966 -234.416)" fill="#e6e6e6" />
              <path id="Path_56" data-name="Path 56" d="M809.929,344.041l-18.29,22.2,7.553,11.964s18.39-28.751,18.8-29.745a6.9,6.9,0,1,0-8.068-4.418Z" transform="translate(-753.348 -243.597)" fill="#ffb6b6" />
              <path id="Path_57" data-name="Path 57" d="M829.217,313.624c-5.52-3.051-21.623,44-4.679,70.757A15.805,15.805,0,0,0,846.6,376.69l8.771-21.467-14.054-4.48-6.712,7.7.934-15.323-7.988-21.777Z" transform="translate(-800.203 -234.719)" fill="#e6e6e6" />
            </g>
          </g>
        </svg>
              )}
          { isMobile && (
          <StaticImage src="https://coinsclone.mo.cloudinary.net/images/workflow/defi-crowdfunding-mobile.webp" 
              alt="defi-crowdfunding-workflow"
              width={392} />
              )}
        
      </div>
    </section>
  )
}

export default HowOur