import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class WhyShould extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="whyshould">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3">Why should you start</span>
                a DeFi Crowdfunding Platform Development?
              </h2>
              <p className="pharagraph head">DeFi Crowdfunding Platform Development helps you make rich and earn an ample amount of profits in a short period. Here we share a few notable reasons to develop a DeFi Crowdfunding Platform.
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/deficrowdfunding/why-should-you-start-defi-crowdfunding-platform-development.svg" alt="Why Should Start nft lendgin platform Development" />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph"  >Decentralized finance crowdfunding platforms have taken the crypto-market by storm.</li>
                <li className="pharagraph" >They are attracting large audiences around the world. They are also attracting big money.
                </li>
                <li className="pharagraph" >They allow people to transfer liquidity to each other without the involvement of the traditional financial systems.</li>
                <li className="pharagraph" > There are multiple reasons why you should develop a DeFi Crowdfunding platform for yourself or for your business. </li>
                <li className="pharagraph" >There is a great upsurge in the demand for such crowdfunding platforms. </li>
                <li className="pharagraph" > You can generate unlimited residual income. The platform can be a breeding ground for unbridled innovation. </li>
                <li className="pharagraph" >The best, they can be easily deployed. </li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould