import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

class TechStack extends React.Component {


  render() {
    return (
    <section className="binancestack technostack dev-tech-stack">
    <div className="container">
      <div className="row table-content">
        <div className="col-md-12 col-lg-6 text-left">
          <h3 className="heading-h2"><span className="bluecolor">Technologies Used</span> For Our DeFi Crowdfunding Platform Development</h3>
          <p className="pharagraph">We at Coinsclone use a set of advanced technologies for creating and deploying an error-free DeFi Crowdfunding Platform Development Services.
          </p>
        </div>
        <div className="col-md-12 col-lg-6">
        <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/whitelabel/technologies-stock.png"
                alt="Technologies stock"
                className='logostck'
                width={573}
              />
        </div>
      </div>
    </div>
  </section>
    )
  }
}

export default TechStack